import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { isMobile, isTablet } from 'react-device-detect';
import { handlerContactForm } from '../../helpers/contact-form';
import { AudioPlayer } from '../../helpers/audio-player';
import { PageDetailsContext } from './pagesWrapper';
import '../../assets/sass/_globals.scss';
import './layouts.scss';

const requiredFields = {
  'your-name': '',
  'your-email': '',
  'your-message': '',
};

const Layout = ({ children, header }) => {
  const { isClient, isContactPage, isAudioOnPage } = useContext(
    PageDetailsContext,
  );
  if (isClient && isContactPage) {
    handlerContactForm('contact-form', requiredFields);
  }
  if (isClient && isAudioOnPage) {
    const placements = document.querySelectorAll('.wp-block-audio');
    [...placements].map((placement) => {
      const temp = document.createElement('div');
      ReactDOM.render(
        <AudioPlayer
          source={placement.querySelector('audio').src}
          caption={placement.querySelector('figcaption').innerText}
        />,
        placement.appendChild(temp),
      );
    });
  }
  return (
    <main
      className={`container ${
        isClient && (isMobile || isTablet) ? 'mobile' : ''
      }`}
    >
      {header && <h2 className={'title'}>{header}</h2>}
      <div>{children}</div>
    </main>
  );
};
export default Layout;
