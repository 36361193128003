import React, { Component, createRef } from 'react';
import '../assets/sass/audio-player.scss';

export class AudioPlayer extends Component {
  state = {
    playPause: 'play',
    currentTime: '0:00',
    seeking: false,
    rangeValue: 0,
  };

  constructor(props) {
    super(props);
    this.player = createRef();
  }

  togglePlayPause = () => {
    const player = this.player.current.querySelector('audio');
    if (player.paused === false) {
      player.pause();
      this.setState({
        playPause: 'play',
      });
    } else {
      player.play();
      this.setState({
        playPause: 'pause',
      });
    }
  };

  getTime(time) {
    if (!isNaN(time)) {
      return (
        Math.floor(time / 60) + ':' + ('0' + Math.floor(time % 60)).slice(-2)
      );
    }
  }

  async downloadFile(source) {
    await fetch(source).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = source.substring(source.lastIndexOf('/') + 1);
        a.click();
      });
    });
  }

  seek(e) {
    const audio = this.player.current.querySelector('audio');
    this.setState({
      rangeValue: e.target.value,
    });
    const seekTo = audio.duration * (e.target.value / 100);
    audio.currentTime = seekTo;
  }

  setRangeValue = () => {
    const audio = this.player.current.querySelector('audio');
    this.setState({
      rangeValue: audio.currentTime * (100 / audio.duration),
    });
  };

  componentDidMount() {
    const player = this.player.current.querySelector('audio');
    player.addEventListener('loadedmetadata', (e) => {
      this.setState({
        duration: this.getTime(e.target.duration),
      });
    });
    player.addEventListener('timeupdate', (e) => {
      this.setState({
        currentTime: this.getTime(e.target.currentTime),
      });
      this.setRangeValue();
    });
  }

  componentWillUnmount() {
    this.player.current.removeEventListener('timeupdate', () => {});
    this.player.current.removeEventListener('loadedmetadata', () => {});
  }

  render() {
    const { source, caption } = this.props;
    const { playPause, currentTime, duration } = this.state;
    return (
      <div className='audio' ref={this.player}>
        <button className='audio__btn' onClick={() => this.togglePlayPause()}>
          <div className={`audio__btn--icon ${playPause}`} />
        </button>
        <div className='audio__controls'>
          <p className='audio__text'>{currentTime}</p>
          <input
            type='range'
            min={0}
            max={100}
            step={0.1}
            value={this.state.rangeValue}
            onChange={(e) => this.seek(e)}
          />
          <p className='audio__text'>{duration}</p>
        </div>
        <audio>
          <source src={source} type='audio/mpeg' />
        </audio>
        <a
          onClick={() => this.downloadFile(source)}
          className='audio__btn audio__btn--icon download'
        />
        <p className='audio__text'>{caption}</p>
      </div>
    );
  }
}
