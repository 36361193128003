export const handlerContactForm = (formId, requestedFields) => {
  const contactForm = document.querySelector(`#${formId}`).closest('form');
  let formBody = requestedFields;

  const getFormBodyInputs = (contactForm, requestedInputs) => {
    let allFormInputFields = contactForm.querySelectorAll('input, textarea');
    allFormInputFields = Array.prototype.slice.call(allFormInputFields);
    return Object.keys(requestedInputs).map((key) =>
      allFormInputFields.filter((input) => input.name === key),
    );
  };

  const setInputsChangeListener = (inputs) => {
    inputs.forEach((input) => {
      input[0].addEventListener('input', handleInputChange);
    });
  };

  const handleInputChange = (input) => {
    const name = input.target.name;
    const value = input.target.value;
    formBody = {
      ...formBody,
      [name]: value,
    };
  };

  const formSubmit = (event, body) => {
    event.preventDefault();

    let formData = new FormData();
    for (let key in body) {
      formData.append(key, body[key]);
    }
    if (
      formValidation(event.target, getFormBodyInputs(event.target, formBody))
    ) {
      fetch(
        `${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_URL}/wp-json/contact-form-7/v1/contact-forms/1465/feedback`,
        {
          method: 'POST',
          body: formData,
        },
      )
        .then((response) => response.json())
        .then((data) => {
          const messageBox = document.querySelector('.wpcf7-response-output');
          messageBox.classList.remove('wpcf7-display-none');
          messageBox.innerText = data.message;
        })
        .catch((error) => {
          throw new Error(error);
        });
    }
  };
  const formValidation = (form, validatedFields) => {
    const emailPattern = /^([a-z]|[A-Z]|[0-9]|[.+_-])+@(([a-z]|[A-Z]|[0-9])+\.([a-z]|[A-Z]|[0-9]){2,3})$/;
    const validation = validatedFields.map((field) => {
      console.log(field);
      if (field[0].value === '') {
        field[0].style.borderColor = 'red';
        field[0].closest('div').querySelector('.required').style.display =
          'block';
        return false;
      }
      if (
        field[0].name === 'your-email' &&
        !field[0].value.match(emailPattern)
      ) {
        field[0].style.borderColor = 'red';
        field[0].closest('div').querySelector('.invalid').style.display =
          'block';
        return false;
      } else {
        field[0].style.borderColor = '#c4c4c4';
        field[0]
          .closest('div')
          .querySelectorAll('.error')
          .forEach((error) => (error.style.display = 'none'));
        return true;
      }
    });
    return !validation.includes(false);
  };
  setInputsChangeListener(getFormBodyInputs(contactForm, requestedFields));
  contactForm.addEventListener('submit', (event) =>
    formSubmit(event, formBody),
  );
};
